import {
  Link,
  Wrapper,
  Image,
  ConnectionView,
  ElectronView,
  ElectronAppButton,
  ElectronMoveWindow,
  Icon,
  ExtensionView,
  SessionRole,
} from "nystem-components";

const PartMenu = () => (
  <ElectronMoveWindow
    renderAs="nav"
    className="items-top fixed top-0 z-20 flex w-full max-w-sm bg-gray-900 text-gray-100"
  >
    <Link className="flex-grow" to="/" match="/" exact={true}>
      <ConnectionView>
        <Image
          className="text-red ml-3 py-1"
          width={40}
          height={30}
          src="/image/remotor.svg"
          alt="logo"
        />
      </ConnectionView>
      <ConnectionView offline="true">
        <Image
          className="text-red ml-3 py-1 grayscale"
          width={40}
          height={30}
          src="/image/remotor.svg"
          alt="logo"
        />
      </ConnectionView>
    </Link>
    <Wrapper>
      <SessionRole userrole="logged-in">
        <Link
          className="border-hover mx-1 py-2 text-center"
          to="/event/list"
          match="/event*"
        >
          <Icon icon="circle-radiation" className="h-8 w-8 p-1" />
        </Link>
        <Link
          className="border-hover mx-1 py-2 text-center"
          to="/device/list"
          match="/device/list*"
        >
          <Icon icon="lightbulb" className="h-8 w-8 p-1" />
        </Link>
      </SessionRole>
      <ExtensionView>
        <a
          className="border-hover mx-1 py-2 text-center"
          href="https://35f3478.remotor.mimicme.se/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon icon="up-right-from-square" className="h-8 w-8 p-1" />
        </a>
      </ExtensionView>
      <Icon icon="square" className="hidden" />
      <Icon icon="xmark" className="hidden" />
      <ElectronView>
        <ElectronAppButton
          className="mt-2 h-8 w-8 cursor-pointer rounded p-2 hover:bg-gray-800"
          button="minus"
        />
        <ElectronAppButton
          className="mt-2 h-8 w-8 cursor-pointer rounded p-2 hover:bg-gray-800"
          buttonActive="edit-copy"
          button="square"
        />
        <ElectronAppButton
          className="mt-2 h-8 w-8 cursor-pointer rounded p-2 hover:bg-gray-800"
          button="xmark"
        />
      </ElectronView>
    </Wrapper>
  </ElectronMoveWindow>
);

export default PartMenu;
/*
      <Link
        className="py-2 border-hover mx-1 w-16 text-center"
        to="/mediaFile/list"
        match="/mediaFile/*"
      >
        VLC
      </Link>
*/
