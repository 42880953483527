import { Wrapper, UseUser, ContentTypeRender } from "nystem-components";

const ConditionalUserRole = ({ model, path }) => {
  const user = UseUser();

  const contains = (array1, array2) => {
    if (typeof array1 === "string") array1 = [array1];
    if (typeof array2 === "string") array2 = [array2];

    for (let i = 0; i < array1.length; i++)
      if (array2.indexOf(array1[i]) !== -1) return true;

    return false;
  };

  const visible = user ? contains(user.role, model.role) : false;

  if (visible)
    return (
      <Wrapper className={model.className}>
        <ContentTypeRender path={path} items={model.item} />
      </Wrapper>
    );
  return null;
};
export default ConditionalUserRole;
