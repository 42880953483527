import components from './core/components/client/index.js';
import connection from './core/connection/client/index.js';
import contentType from './core/contentType/client/index.js';
import core from './core/core/client/index.js';
import database from './core/database/client/index.js';
import file from './core/file/client/index.js';
import pwa from './core/pwa/client/index.js';
import router from './core/router/client/index.js';
import session from './core/session/client/index.js';
import chromextension from './module/chromextension/client/index.js';
import electron from './module/electron/client/index.js';

export default function(app) {
  components(app);
  connection(app);
  contentType(app);
  core(app);
  database(app);
  file(app);
  pwa(app);
  router(app);
  session(app);
  chromextension(app);
  electron(app);
}
