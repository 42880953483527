import { useEffect } from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";

const types = {
  primary: "bg-blue-500 hover:bg-blue-500 text-white",
  secondary:
    "bg-gray-800 hover:bg-gray-700 text-gray-200 border border-gray-700",
  danger: "bg-red-700 hover:bg-red-600 text-white",
  warning: "bg-yellow-600 hover:bg-yellow-500 text-white",
  success: "bg-green-600 hover:bg-green-500 text-white",
  info: "btn",
  error: "btn",
  default: "bg-gray-800 hover:bg-gray-700 text-gray-200 border border-gray-700",
  primaryDisabled: "bg-blue-400 cursor-not-allowed",
  secondaryDisabled: "bg-gray-600 text-white cursor-not-allowed",
  dangerDisabled: "bg-red-400 text-white cursor-not-allowed",
  warningDisabled: "bg-yellow-300 text-white cursor-not-allowed",
  infoDisabled: "btn",
  errorDisabled: "btn",
  defaultDisabled: "bg-gray-300 text-white cursor-not-allowed",
};

const sizes = {
  xs: "py-1 px-2 text-xs",
  sm: "py-1 px-2 text-sm",
  base: "py-2 px-4 text-base",
  lg: "py-4 px-6 text-lg",
  xl: "py-4 px-8 text-xl",
  "2xl": "py-4 px-8 text-xl2",
};

const getId = ({ path = "", id = "" }) => {
  if (path && id) return `${path}.${id.substring(id.lastIndexOf(".") + 1)}`;
  if (id) return id;
  return "";
};
const getValue = ({ value, path }) =>
  path ? path.split(".").reduce((val, key) => val && val[key], value) : value;

const MultigroupActive = ({ model, value = [], view, path = "" }) => {
  const { show = [], className, field } = model;
  const fieldId = field[0].id.replace(`${model.id}.`, "");
  const valPath = getId({ path, id: model.id });

  let at = value.map((item) => item[fieldId]).indexOf(true);
  if (at === -1) at = 0;

  useEffect(() => {
    const change = ({ path = "", value }) => {
      if (!path.startsWith(valPath)) return;

      const parts = path.split(".");
      if (parts[parts.length - 1] !== fieldId) return;

      getValue({ path: valPath, value }).forEach((item, index) => {
        const iPath = `${valPath}.${index}.${fieldId}`;
        if (path === iPath) return;
        delete item[fieldId];
      });
    };

    view.on("change", 500, change);
    return () => {
      view.off("change", change);
    };
  }, [fieldId, valPath, view]);

  const setNext = () => {
    const next = at === value.length - 1 ? 0 : at + 1;

    view.setValue({
      path: `${getId({ path, id: model.id })}.${next}.${fieldId}`,
      value: true,
    });
  };

  if (!show.length || !value.length) return null;
  const pos = Math.trunc((at / (value.length - 1)) * 100);

  return (
    <Wrapper
      className={[
        className,
        "select-none rounded border border-gray-500 py-1 px-2 text-sm shadow-sm",
      ]}
      style={{
        background: `linear-gradient(to right, rgb(59 130 246), rgb(59 130 246) ${pos}%, rgb(31 41 55) ${pos}%, rgb(31 41 55) 100%)`,
      }}
      onClick={model.noOnClick ? undefined : setNext}
    >
      <ContentTypeRender path={path.replace(model.id, "")} items={show} />
    </Wrapper>
  );
};

export default MultigroupActive;
