import {
  Inserter,
  Wrapper,
  SessionRole,
  PartMenu,
  DragAndDropContext,
  ElectronView,
  ElectronAppButton,
} from "nystem-components";
import "../../../index.css";
import "./index.css";

const Index = () => (
  <DragAndDropContext>
    <Inserter match="/background.html" source="/denon/extension/upstairs" />
    <SessionRole userrole="logged-out">
      <ElectronView>
        <ElectronAppButton
          className="float-right h-8 w-8 cursor-pointer rounded px-2 hover:bg-gray-100"
          button="close"
        />
      </ElectronView>
      <PartMenu />
      <Inserter
        source="/adminUser/login"
        className="mt-14 max-w-sm px-10 text-gray-100"
        match="*"
      />
    </SessionRole>
    <SessionRole userrole="logged-in" className="text-base">
      <PartMenu />
      <Wrapper renderAs="main" className="mt-14 max-w-sm text-gray-100">
        <Inserter match="/" source="/event/frontAll" />
        <Inserter match="/popup.html" source="/event/frontAll" />
        <Inserter match="/denon/*" />
        <Inserter match="/energy/*" />
        <Inserter match="/adminUser/*" />
        <Inserter match="/event/*" />
        <Inserter match="/touchscreen/*" />
        <Inserter match="/device/*" />
        <Inserter match="/schedule/*" />
        <Inserter match="/unit/*" />
      </Wrapper>
    </SessionRole>
    <Inserter source="/denon/electron" match="*" />
  </DragAndDropContext>
);

export default Index;
