import { useEffect } from "react";
import app from "nystem";

const ElectronRemotecontrol = () => {
  useEffect(() => {
    const sendEvent = ({ connected }) => {
      if (!connected) return;

      app().connection.event("emit", { type: "remotelyRunServer" });
    };

    if (app().connection.connected)
      app().connection.event("emit", { type: "remotelyRunServer" });
    app().connection.on("connection", sendEvent);
    return () => {
      app().connection.off("connection", sendEvent);
    };
  }, []);

  return null;
};
export default ElectronRemotecontrol;
