import { useRef } from "react";
import { InputWrapper, Wrapper, Input } from "nystem-components";
import app from "nystem";

const IntRange = ({ model, value, setValue }) => {
  const procent = parseInt(((value - model.min) / model.max) * 100, 10);
  const ref = useRef();

  return (
    <InputWrapper
      model={{
        ...model,
        classNameInput: "flex flex-grow items-center",
        classNameLabel: " ",
      }}
      className={model.componentClassName}
    >
      <Input
        ref={ref}
        placeholder={app().t(model.text)}
        className="w-full"
        value={value}
        onChange={(value) => setValue(value)}
        type="range"
        min={model.min}
        max={model.max}
      />
      <Wrapper className={model.textClass}>{`(${procent}%)`}</Wrapper>
    </InputWrapper>
  );
};
export default IntRange;
