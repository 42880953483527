import { useEffect, useState } from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";
import app from "nystem";

const StyleDelay = ({ model, path }) => {
  const { className, renderAs } = model;
  const [active, setActive] = useState(false);

  useEffect(() => {
    app()
      .delay(model.delay)
      .then(() => setActive(true));
  }, [model]);

  const item = active ? model.itemActive : model.itemNotActive;
  if (!item) return null;

  return (
    <Wrapper className={className} renderAs={renderAs}>
      <ContentTypeRender path={path} items={item} />
    </Wrapper>
  );
};

export default StyleDelay;
