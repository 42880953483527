import { useEffect } from "react";
import app from "nystem";

const ExtensionBookmarks = ({ model }) => {
  useEffect(() => {
    const saveItem = async ({
      id,
      dateAdded,
      parentId,
      title,
      url,
      children,
    }) => {
      await app().database[model.contentType].save({
        data: { _id: id, _crdate: dateAdded, parentId, title, url },
      });

      if (!children) return;

      for await (const child of children) {
        await app().delay(10);
        await saveItem(child);
      }
    };

    window.chrome.bookmarks.getTree((data) => saveItem(data[0]));
  }, [model.contentType]);

  return null;
};

export default ExtensionBookmarks;
