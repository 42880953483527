import { DateInput, UseSearch, RouterUseQueryStore } from "nystem-components";

const DateExposedField = ({ model, view }) => {
  const [from, setFrom] = RouterUseQueryStore(model.saveIdFrom, "int");
  const [to, setTo] = RouterUseQueryStore(model.saveIdTo, "int");
  UseSearch({
    view,
    id: model.id,
    value: from && `>${from - 1}`,
    noListen: true,
  });
  UseSearch({ view, id: model.id, value: to && `<${to + 1}`, noListen: true });

  const modelFrom = {
    id: "from",
    placeholder: "From",
    clearButton: true,
  };
  const modelTo = {
    id: "to",
    placeholder: "To",
    clearButton: true,
  };
  const style = {
    width: "150px",
  };
  return (
    <div className={`${model.className} form-inline`}>
      {this.model.text}
      <DateInput
        style={style}
        model={modelFrom}
        value={from}
        setValue={setFrom}
      />
      {" - "}
      <DateInput style={style} model={modelTo} value={to} setValue={setTo} />
    </div>
  );
};
export default DateExposedField;
