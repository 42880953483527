import { useEffect } from "react";

const BooleanVibrate = ({ value, model }) => {
  useEffect(() => {
    if (!navigator.vibrate) return;
    const { atState, pattern } = model;
    if (value === atState)
      navigator.vibrate(pattern ? JSON.parse(pattern) : 300);
  }, [model, value]);

  return null;
};
export default BooleanVibrate;
