import { useEffect, useState } from "react";
import { Wrapper } from "nystem-components";
import "./loading.css";

const Loading = () => {
  const [inDelay, setInDelay] = useState(true);
  useEffect(() => {
    const delayTimer = setTimeout(() => setInDelay(false), 200);
    return () => {
      clearTimeout(delayTimer);
    };
  }, []);

  return inDelay ? null : (
    <Wrapper className="loading m-2 h-2 rounded-lg shadow" />
  );
};

export default Loading;
