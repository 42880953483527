import { IntView, TextView, Wrapper, BooleanView } from "nystem-components";

const DynamicColorPicker = ({ model, value, setValue }) => (
  <>
    <Wrapper className="flex items-center">
      <TextView model={{ ...model }} value={value} />
      <Wrapper
        className="ml-2 h-6 w-6 rounded-full"
        style={{ backgroundColor: value }}
      ></Wrapper>
    </Wrapper>
    <Wrapper renderAs="p" className="text-sm">
      {model.description}
    </Wrapper>
  </>
);

const IntDeviceView = (props) => {
  const { deviceType, className } = props.model;

  if (deviceType === "switch") {
    return (
      <BooleanView
        {...props}
        model={{ ...props.model, className: [className, "w-4", "h-4"] }}
      />
    );
  }
  if (["light", "huelight"].includes(deviceType)) {
    return (
      <DynamicColorPicker
        {...props}
        value={
          props.value ? `#${props.value.toString(16).padStart(6, "0")}` : ""
        }
      />
    );
  }

  return <IntView {...props} />;
};
export default IntDeviceView;
