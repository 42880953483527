import { Wrapper } from "nystem-components";

const Offline = () => (
  <Wrapper className="cssload-loader">
    <Wrapper className="cssload-inner cssload-one" />
    <Wrapper className="cssload-inner cssload-two" />
    <Wrapper className="cssload-inner cssload-three" />
  </Wrapper>
);
export default Offline;
