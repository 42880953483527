import { Wrapper, ContentTypeRender } from "nystem-components";

const ViewButtonSetValueFields = ({ model, view, path }) => {
  const modelValue =
    model.value.replace(/[0-9]/, "") === ""
      ? parseInt(model.value, 10)
      : model.value;

  const setValue = () =>
    view.setValue({ path: model.field, value: modelValue });

  return (
    <Wrapper className={model.className} onClick={() => setValue()}>
      <ContentTypeRender path={path} items={model.item} />
    </Wrapper>
  );
};

export default ViewButtonSetValueFields;
