import { useState, useEffect } from "react";
import { Wrapper } from "nystem-components";
import app from "nystem";

const useValue = (field, view, path) => {
  const fullPath = path ? `${path}.${field}` : field;
  const [value, setValue] = useState(view.getValue(fullPath));

  useEffect(() => {
    const update = () => {
      const val = view.getValue(fullPath);
      setValue(val);
    };

    view.on("change", -10000, update);
    return () => {
      view.off("change", update);
    };
  }, [value, view, path, fullPath]);

  return [
    value,
    async (val) => {
      await view.setValue({ path: fullPath, value: val });
      setValue(val);
    },
  ];
};

const useSource = ({ model, value, view }) => {
  const [data, setdata] = useState(false);

  useEffect(() => {
    const update = async () => {
      const { data } = await app().database[model.source].get({
        id: value,
      });
      if (!data) setdata(false);
      else
        setdata(
          data.state.map((item, index) => ({ _id: index, text: item.name }))
        );
    };

    update();
    return () => {};
  }, [model.source, value, view]);

  return data;
};

const ReferenceStateView = ({ model, view, value, path }) => {
  const { field } = model;
  const [at] = useValue(field, view, path);
  const option = useSource({ model, value, view });

  if (!option) return null;

  return (
    <Wrapper
      className={model.className}
      renderAs={model.renderAs}
      translate={model.translate}
      title={
        model.title || model.fallback ? value || model.fallback : undefined
      }
    >
      {option[at]?.text}
    </Wrapper>
  );
};
export default ReferenceStateView;
