import { Wrapper, UseUser } from "nystem-components";

const contains = (array1, array2) => {
  if (typeof array1 === "string") array1 = [array1];
  if (typeof array2 === "string") array2 = [array2];

  for (let i = 0; i < array1.length; i++)
    if (array2.indexOf(array1[i]) !== -1) return true;

  return false;
};

const Role = ({ className, children, role }) => {
  const user = UseUser();

  const visible = contains(
    user && user.role ? ["logged-in"].concat(user.role) : ["logged-out"],
    role.split(" ")
  );

  if (visible) return <Wrapper className={className}>{children}</Wrapper>;
  return null;
};
export default Role;
