import { useEffect } from "react";
import app from "nystem";

const ViewButtonEnergyNow = ({ model, view }) => {
  const emitterByType = {
    connection: {
      event: (type, data = {}) => app().connection.emit({ type, ...data }),
    },
    view: view,
    baseView: view.baseView,
    baseViewBaseView: view.baseView?.baseView,
    baseViewBaseViewBaseView: view.baseView?.baseView?.baseView,
    app: app(),
  };
  const emitter = emitterByType[model.eventType || "connection"];

  useEffect(() => {
    const update = async () => {
      const { energy = [] } = await emitter.event(model.event);

      const value = {};
      energy.forEach((power, pos) => {
        value[model.fields[pos]] = power;
      });

      view.setValue({ value });
    };
    const timer = setInterval(update, 500);
    update();

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
export default ViewButtonEnergyNow;
